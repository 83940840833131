const { Component, h } = require('preact');
const Timestamp = require('../ui/Timestamp');

class MessagesList extends Component {
  render() {
    let { messages, title } = this.props;
    let useTitle = title || 'Messages';

    return (
      <ul className="table-view" key="messages-list">
        <li className="table-view-cell table-view-divider">{useTitle}</li>
        {messages.map(message => (
          <li className="table-view-cell" key={'message-id-' + message.id}>
            {message.content}
            <p><Timestamp value={message.dtCreated} /></p>
            <p>{Object.keys(message.to.churchIds).join(', ')}</p>
          </li>
        ))}
      </ul>
    );
  }
}

module.exports = MessagesList;
