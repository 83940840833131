const actions = require('./actions');
const Index = require('../components/Index');
const Preact = require('preact');
const store = require('./store');
import { getCurrentUser, getNewUserObject } from './actions/users';
import { auth, db, messaging, messagingSupported } from './firebase';
import './messaging';

// Expose some vars for debugging in dev/test
if (process.env.NODE_ENV !== 'production') {
  window.actions = actions;
  window.auth = auth;
  window.db = db;
  window.store = store;
  require('preact/debug');
}

// Require routes to start this whole thing...
require('./routes');

// Require main CSS file to compile it...
require('../assets/app/css/app.css');

// Firebase setup...
function setupFirebase() {
  // Setup auth
  auth.onAuthStateChanged(function(authUser) {
    if (authUser) {
      db.collection('users')
        .doc(authUser.uid)
        .get()
        .then(doc => {
          let profileData = authUser.providerData ? authUser.providerData[0] || {} : {};
          let user = Object.assign(getNewUserObject(), profileData, { uid: authUser.uid, email: authUser.email, isAnonymous: authUser.isAnonymous }, doc.data());

          store.set('user', getCurrentUser(user));
        });
    } else {
      store.set('user', null);
    }
  });
}
setupFirebase();

// MOUNT client app
const el = document.getElementById('content');

// For some reason I have to empty out the element first? Preact, wat?
el.textContent = '';

Preact.render(Preact.h(Index), el);

// Register service worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('../../sw.js').then(function(registration) {
      // Tell Firebase to use our service worker
      if (messagingSupported()) {
        messaging.useServiceWorker(registration);
      }
      // Registration was successful
      // console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, function(err) {
      // registration failed :(
      console.log('ServiceWorker registration failed: ', err);
    });
  });
}
