import { messaging } from './firebase';
import * as messagesActions from './actions/messages';
import { navigateTo } from './actions/navigation';

// Setup messaging
// messaging.usePublicVapidKey(process.env.FIREBASE_MESSAGING_VAPID_KEY);

// When message is received (WHEN APP IS IN FOCUS)...
messaging.onMessage(function(payload) {
  console.log('[sw] Message received. ', payload);

  let message = payload.notification;

  // ...
  let icon = '/img/apple-icon-144x144.png';
  let notification = new Notification(message.title, { body: message.body, icon, data: message });

  setTimeout(notification.close.bind(notification), 4000);

  notification.addEventListener('click', function () {
    if (message.click_action) {
      navigateTo(message.click_action);
    }
  });
});

messaging.onTokenRefresh(() => messagesActions.remote.getTokenAndUpdateUser());
