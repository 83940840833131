const { h, Component } = require('preact');

class Tabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: props.activeTab || false,
    };
  }

  handleTabClick = (tab) => {
    // Return click handler function
    return (e) => {
      this.setState({ activeTab: tab.id });

      if (this.props.onClick) {
        this.props.onClick(e, tab.id);
      }
    };
  };

  render() {
    let tabs = this.props.tabs;
    let currentTab = tabs.find(tab => tab.id === this.state.activeTab);

    if (!currentTab) {
      currentTab = tabs[0];
    }

    return (
      <div className="tabs-container">
        <div className="tabs-tabs">
          <div className="segmented-control">
            {tabs.map(tab => {
              return <a className={'control-item' + (currentTab.id === tab.id ? ' active' : '')} onClick={this.handleTabClick(tab)}>{tab.title}</a>;
            })}
          </div>
        </div>
        <div className="tab-content">
          {currentTab.content}
        </div>
      </div>
    );
  }
}

module.exports = Tabs;
