const store = require('../store');

/**
 * Navigate to given path
 */
export function navigateTo(path) {
  let normalizedRoute = normalizeUrl(path);

  history.pushState(null, document.title, path);
  store.set('ui.route', '/' + normalizedRoute);
}


/**
 * Normalize URL to trim start and end slashes off
 */
export function normalizeUrl(url) {
  if (url.startsWith('/')) {
    url = url.substr(1);
  }

  if (url.endsWith('/')) {
    url = url.substring(0, url.length - 1);
  }

  return url;
}
