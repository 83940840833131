const { Component, h } = require('preact');

class ChurchesList extends Component {
  render() {
    let { churches, title } = this.props;
    let useTitle = title || 'Churches';

    return (
      <ul className="table-view" key="churches-list">
        <li className="table-view-cell table-view-divider">{useTitle}</li>
        {churches.map(church => (
          <li className="table-view-cell" key={'church-id-' + church.id}>
            <a className="navigate-right" href={'/' + church.id}>
              {church.name}
            </a>
          </li>
        ))}
      </ul>
    );
  }
}

module.exports = ChurchesList;
