const { db, messaging } = require('../firebase');
const { flatten } = require('../../../functions/shared/arrays');
const churchActions = require('./churches');
const { error } = require('./notifications');
const { snapshotToArray } = require('../../../functions/shared/firebase');
const userActions = require('./users');

export const remote = {
  create(data) {
    const user = userActions.getCurrentUser();
    let msg = {
      dtCreated: new Date(),
      content: data.content,
      from: {
        userId: user.uid,
      },
      to: {
        churchId: data.churchId,
      },
      isPublic: true,
    };

    return db.collection('messages')
      .doc()
      .set(msg)
      .then(() => msg);
  },

  /**
   * Find all messages for given church
   */
  findByChurchShortcode(shortcode) {
    let code = churchActions.formatShortcode(shortcode);

    return db.collection('messages')
      .where('to.churchIds', 'array-contains', code)
      .where('isPublic' , '==', true)
      .get()
      .then(snapshotToArray);
  },

  /**
   * Find all messages for current user
   */
  findByCurrentUser() {
    const user = userActions.getCurrentUser();

    if (!user) {
      return Promise.resolve([]);
    }

    let messagePromises = [];

    // Get messages for churches
    if (user.following && user.following.churchIds) {
      messagePromises = messagePromises.concat(Object.keys(user.following.churchIds).map(exports.remote.findByChurchShortcode));
    }

    return Promise.all(messagePromises)
      .then(results => {
        let messages = flatten(results);

        // @TODO: Remove or merge dupes... Can get dupes when message is in multiple groups or churches user follows

        return messages;
      }).catch(error);
  },

  getToken() {
    return messaging.getToken().catch(error);
  },

  getTokenAndUpdateUser() {
    return messaging.getToken().then(function(currentToken) {
      if (currentToken) {
        return userActions.remote.addPushTokenToCurrentUser(currentToken);
      }
    }).catch(error);
  },

  requestPermissionAndUpdateToken() {
    return messaging.requestPermission().then(function() {
      // Get Instance ID token. Initially this makes a network call, once retrieved
      // subsequent calls to getToken will return from cache.
      exports.remote.getTokenAndUpdateUser();
    }).catch(error);
  }
};

export function requestPermission() {
  return messaging.requestPermission();
}
