import { Component, h } from 'preact';

/**
 * Create object with provided arrays of keys and values
 *
 * @param {String[]} keys
 * @param {Array} values
 */
function zipObject(keys, values) {
  return keys.reduce(function(object, currentValue, currentIndex) {
    object[currentValue] = values[currentIndex];

    return object;
  }, {});
}

class ApiForm extends Component {
  onSubmit = (e) => {
    e.preventDefault();

    let formData = this.getFormValues(this.form);

    // Call 'onSubmit' method if present with formatted data
    if (this.props.onSubmit !== undefined) {
      this.props.onSubmit(e, formData);
    }

    return false;
  };

  getFormValues = (form) => {
    let fieldsArray = Array.from(new FormData(form).entries());
    let fieldNames = fieldsArray.map(f => f[0]);
    let fieldValues = fieldsArray.map(f => f[1]);

    return zipObject(fieldNames, fieldValues);
  };

  render() {
    return (
      <form className="form-horizontal" onSubmit={this.onSubmit} ref={(form) => this.form = form}>
        {this.props.children}
      </form>
    );
  }
}

module.exports = ApiForm;
