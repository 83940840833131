/**
 * firebase.js
 *
 * NOTE: This file is used both client-side AND server-side for Firebase
 * functions, so it SHOULD NOT HAVE ANY DEPENDENCIES on other app code. This
 * file is ONLY for pure, portable firebase functions.
 */

function docToObject(doc) {
  if (!doc.exists) {
    return Promise.reject(new Error('File not found!'));
  }

  return Object.assign({ id: doc.id }, doc.data());
}

/**
 * Query snapshot to array of results
 */
function snapshotToArray(snapshot) {
  let results = [];

  if (snapshot.empty) {
    return results;
  }

  snapshot.forEach(doc => {
    let obj = docToObject(doc);

    results.push(obj);
  });

  return results;
}

module.exports = {
  docToObject,
  snapshotToArray,
};

