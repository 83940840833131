const ApiForm = require('./ApiForm');
import { auth, firebase } from '../client/firebase';
const { error, promptDialog } = require('../client/actions/notifications');
const { navigateTo } = require('../client/actions/navigation');
const { Component, h } = require('preact');
const PromptDialog = require('./ui/PromptDialog');
const Tabs = require('./ui/Tabs');
const usersActions = require('../client/actions/users');

let userData = false;
let recaptchaVerifier;
let recaptchaWidgetId;
//let recaptchaConfirmationResult;

class UserProfile extends Component {
  constructor() {
    super();

    this.state = {
      modalOpen: false,
    };
  }

  setupRecaptcha() {
    recaptchaVerifier = new firebase.auth.RecaptchaVerifier('phone-login-button', {
      'size': 'invisible',
      'callback': window.__recaptchaOnSubmit,
      'error': function(err) {
        error(err);
      }
    });

    if (!recaptchaWidgetId) {
      recaptchaVerifier.render().then(function(widgetId) {
        recaptchaWidgetId = widgetId;
      }).catch(error);
    }
  }

  onSubmitPhone = (e, data) => {
    usersActions.remote.hasUserWithPhoneNumber(data.tel)
      .then(hasUser => {
        if (!hasUser && data._mode === 'login') {
          error('No user found with provided telephone number. Have you registered for an account?');
          return false;
        }

        userData = data;
        this.setState({ modalOpen: true });
      })
      .catch(error);
  };

  recaptchaReset = () => {
    if (window.grecaptcha && recaptchaWidgetId) {
      window.grecaptcha.reset(recaptchaWidgetId);
    }

    recaptchaVerifier = undefined;
    recaptchaWidgetId = undefined;

    this.setState({ modalOpen: false });
  };

  renderSMSPrompt() {
    return (
      <PromptDialog isOpen={this.state.modalOpen} onClose={this.recaptchaReset}
        afterRender={() => {
          this.setupRecaptcha();
        }}
      >
        {() => (
          <ApiForm onSubmit={() => {
            if (auth.settings.appVerificationDisabledForTesting) {
              window.__recaptchaOnSubmit();
            }
          }}>
            <p>You will be sent a text message via SMS with a login code. Once you enter a matching code, your authentication will be complete.</p>
            <button className="btn btn-positive btn-block" id="phone-login-button">Text Me a Login Code</button>
            <p className="text-help text-center">Standard SMS and data rates will apply.</p>
          </ApiForm>
        )}
      </PromptDialog>
    );
  }

  renderLoginForm() {
    return (
      <ApiForm onSubmit={this.onSubmitPhone} key="authLogin">
        <input type="hidden" name="_mode" value="login" />
        <p>Phone Number</p>
        <input className="text-lowercase" type="tel" name="tel" id="tel" placeholder="405-555-1212" required />
        <button className="btn btn-positive btn-block" type="submit">Login</button>
      </ApiForm>
    );
  }

  renderRegisterForm() {
    return (
      <ApiForm onSubmit={this.onSubmitPhone} key="authRegister">
        <input type="hidden" name="_mode" value="register" />
        <p>Your Name</p>
        <input type="text" name="displayName" placeholder="John Doe" required />
        <p>Email Address</p>
        <input className="text-lowercase" type="email" name="email" placeholder="email@example.com" required />
        <p>Phone Number</p>
        <input type="tel" name="tel" placeholder="555-555-1212" required />
        <button className="btn btn-positive btn-block" type="submit">Register</button>
      </ApiForm>
    );
  }

  render() {
    return (
      <div className="flex-container m2">
        <div className="flex-item">
          <Tabs
            activeTab={this.props.tab}
            tabs={[
              {
                id: 'login',
                title: 'Login',
                content: this.renderLoginForm()
              },
              {
                id: 'register',
                title: 'Register',
                content: this.renderRegisterForm()
              },
            ]} />
          <hr />
          <p className="text-help text-center">Standard SMS and data rates apply.</p>
          {this.state.modalOpen ? this.renderSMSPrompt() : null}
        </div>
      </div>
    );
  }
}

function __recaptchaOnSubmit() {
  const tel = userData.tel;

  // reCAPTCHA solved, allow signInWithPhoneNumber.
  usersActions.remote.loginWithPhoneNumber(tel, recaptchaVerifier)
    .then(function(confirmationResult) {
      //recaptchaConfirmationResult = confirmationResult;

      promptDialog('Enter confirmation code you received via SMS:')
        .then(function(code) {
          // Confirm with code
          confirmationResult.confirm(code)
            .then(function() {

              // User registration - Set name and email on user profile
              if (userData && userData._mode === 'register') {
                usersActions.remote.updateUserProfile(userData);
              }

              navigateTo('/profile');
            }).catch(error);
        });
    })
    .catch(function(err) {
      error(err);
      // TODO: RESET CAPTCHA
    });

}
window.__recaptchaOnSubmit = __recaptchaOnSubmit; // SUPER Gross, but have to do this for reCAPTCHA library...

module.exports = UserProfile;
