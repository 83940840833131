const ApiForm = require('./ApiForm');
const churchActions = require('../client/actions/churches');
const { Component, h } = require('preact');
const { error } = require('../client/actions/notifications');
const navigationActions = require('../client/actions/navigation');

class Search extends Component {
  onSubmit(e, data) {
    churchActions.remote.findByShortcode(data.code)
      .then((church) => {
        navigationActions.navigateTo('/' + church.shortcode);
      })
      .catch(() => {
        error('No church exists with the provided code');
      });
  }

  renderSearchForm() {
    return (
      <ApiForm onSubmit={this.onSubmit}>
        <p>Find your church by code:</p>
        <input className="text-uppercase" type="text" name="code" placeholder="CODE" />
        <button className="btn btn-positive btn-block">Search</button>
      </ApiForm>
    );
  }

  render() {
    return (
      <div className="flex-container m2">
        <div className="flex-item">
          {this.renderSearchForm()}
        </div>
        <div className="flex-item">
          <div className="content-padded">
            <p>Want to use ChurchMint for your church?</p>
          </div>
          <div className="card">
            <ul className="table-view">
              <li className="table-view-cell">
                <a className="navigate-right" href="/churches/new">Add Your Church to ChurchMint</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

module.exports = Search;
