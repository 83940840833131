const Async = require('./Async');
const Alerts = require('./ui/Alerts');
const { Component, h } = require('preact');
const routes = require('../client/routes');
const store = require('../client/store');

class Index extends Component {
  getContent() {
    let promise = routes.getContent();

    return h(Async, { promise });
  }

  renderAlerts() {
    return <Alerts />;
  }

  render() {
    let { route } = this.props;
    let pathName = route.path;

    return (
      <div>
        <header className="bar bar-nav">
          <h1 className="title">ChurchMint</h1>
        </header>
        <div className="content">
          {this.getContent()}
        </div>
        {this.renderAlerts()}
        <nav className="bar bar-tab">
          <a className={'tab-item' + (pathName === 'messages' ? ' active' : '')} href="/messages">
            <span className="icon icon-list" />
            <span className="tab-label">Messages</span>
          </a>
          <a className={'tab-item' + (pathName === 'search' ? ' active' : '')} href="/search">
            <span className="icon icon-search" />
            <span className="tab-label">Search</span>
          </a>
          <a className={'tab-item' + (pathName === 'profile' ? ' active' : '')} href="/profile">
            <span className="icon icon-person" />
            <span className="tab-label">Profile</span>
          </a>
        </nav>
      </div>
    );
  }
}

module.exports = store.subscribe(Index, {
  route: 'ui.route',
  user: 'user',
});
