const { auth, db } = require('../firebase');
const { docToObject } = require('../../../functions/shared/firebase');
const { flatten } = require('../../../functions/shared/arrays');
const store = require('../store');

export const remote = {
  /**
   * Create new church
   */
  create(data) {
    let user = auth.currentUser;
    let code = exports.formatShortcode(data.shortcode);

    return exports.remote.isShortcodeTaken(data.shortcode)
      .catch(() => Promise.resolve(false))
      .then(isTaken => {
        if (isTaken) {
          return Promise.reject(new Error('Church shortcode is already in use. Please choose another shortcode.'));
        }

        data = _formatChurchFields(data);

        // Data formatting...
        data.managers.userIds.push(user.uid); // Add 'uid' to church as creating user
        data.shortcode = code; // Overwrite with formatted shortcode

        return db.collection('churches')
          .doc(code)
          .set(data)
          .then(() => data);
      });
  },

  isShortcodeTaken(shortcode) {
    return remote.findByShortcode(shortcode)
      .then(() => true)
      .catch(() => false);
  },

  /**
   * Fetch church record by shortcode
   */
  findByShortcode(shortcode) {
    let code = exports.formatShortcode(shortcode);
    let err;

    if (code.length < 4) {
      err = 'Shortcode must be at least 4 characters long';
    }

    if (err) {
      return Promise.reject(new Error(err));
    }

    return db.collection('churches')
      .doc(code)
      .get()
      .then(docToObject);
  },

  /**
   * Find all churches that user follows
   */
  findByUserFollowing(userObj) {
    let user = userObj || store.get('user');
    let churchIds = user.following.churchIds;
    let userChurches = churchIds.map(exports.remote.findByShortcode); // @TODO: Fix this horrible garbage...

    return Promise.all(userChurches)
      .then(allResults => {
        return flatten(allResults);
      });
  },
};

/**
 * Is the current user MANAGING the provided church?
 *
 * @return {Boolean}
 */
export function userIsManagingChurch(church, userObj) {
  let user = userObj || store.get('user');

  return church && church.managers && church.managers.userIds[user.id] === true;
}

/**
 * Ensure user object has all required nested fields before mutating
 *
 * @return {Object}
 */
function _formatChurchFields(church) {
  let data = Object.assign({}, church);

  // Church manager data
  data.managers = data.managers || {};
  data.managers.userIds = data.managers.userIds || [];

  return data;
}

export function formatShortcode(shortcode) {
  return String(shortcode).toUpperCase();
}
