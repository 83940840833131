const Loading = require('./ui/Loading');
const { Component, h } = require('preact');

class Async extends Component {
  constructor(props) {
    super(props);

    this.renderLoading = this.renderLoading.bind(this);

    this.state = {
      content: null,
      isResolved: false,
      promise: props.promise,
    };
  }

  // For React/Preact/Inferno, etc.
  componentWillReceiveProps (nextProps) {
    if (nextProps.promise !== this.props.promise) {
      this.setState({
        content: null,
        promise: nextProps.promise,
        isResolved: false,
      });
    }
  }

  // For React 16.x
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.promise !== prevState.promise) {
      return {
        content: null,
        promise: nextProps.promise,
        isResolved: false,
      };
    }

    return null;
  }

  renderLoading() {
    return h(Loading);
  }

  render() {
    if (!this.state.isResolved) {
      this.props.promise.then((content) => {
        this.setState({ isResolved: true, content });
      });

      return this.renderLoading();
    }

    return this.state.content;
  }
}

module.exports = Async;
