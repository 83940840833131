const { h, Component } = require('preact');

class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: props.isActive || false,
    };
  }

  onClick = (e) => {
    let newState = !this.state.isActive;
    this.setState({ isActive: newState });

    if (this.props.onClick) {
      this.props.onClick(e, newState);
    }
  };

  renderMenuItem(item) {
    let linkProps = {};

    // Menu item click handler
    if (item.onClick) {
      linkProps.onClick = (e) => {
        this.onClick(e); // Close menu
        item.onClick(e);
      };
    }

    // Regular link href
    if (item.href) {
      linkProps.href = item.href;
    }

    return (
      <li className="table-view-cell">
        <a className="link" {...linkProps}>
          {item.title}
          {item.help ? <p className="help">{item.help}</p> : null}
        </a>
      </li>
    );
  }

  render() {
    let { options, title } = this.props;
    let titleDefault = 'Options';

    return (
      <div className={'menu' + (this.state.isActive ? ' active' : '')}>
        <div className="menu-icon">
          <a onClick={this.onClick}><span className="icon icon-bars" /> Options</a>
        </div>
        <div id="popover" className={'popover' + (this.state.isActive ? ' visible' : '')}>
          <header className="bar bar-nav">
            <h1 className="title">{title || titleDefault}</h1>
          </header>
          <ul className="table-view">
            {options ? options.map(item => this.renderMenuItem(item)) : null}
          </ul>
        </div>
      </div>
    );
  }
}

module.exports = Menu;
