import store from '../store';

const NOTIFICATION_TIMEOUT = 6000;

/**
 * Add error message to alert
 *
 * @param {String} message
 * @return {String} ID of the notification created
 */
export function error(message) {
  if (message instanceof Error) {
    // Log full error with stack trace to console for easier debugging
    console.error(message); // eslint-disable-line no-console
    message = message.message;
  }

  return create('error', message);
}


function create(type, message, autoDismiss = true) {
  let alerts = getAlerts();
  let id = Date.now();

  alerts.push({ id, message, type });

  if (autoDismiss === true) {
    setTimeout(() => dismiss(id), NOTIFICATION_TIMEOUT);
  }

  store.set('ui.alerts', alerts);

  return id;
}

function dismiss(id) {
  let alerts = getAlerts();

  let alertIndex = alerts.findIndex(a => a.id === id);

  if (alertIndex !== -1) {
    if (alerts.length > 1) {
      alerts.splice(alertIndex, 1);
    } else {
      alerts.pop();
    }
  }

  store.set('ui.alerts', alerts);
}

function getAlerts() {
  return store.get('ui.alerts');
}

/**
 * User interaction dialog (confirm yes/no)
 */
export function confirmDialog(msg) {
  return new Promise(function (resolve, reject) {
    let confirmed = window.confirm(msg);

    return confirmed ? resolve(true) : reject(false);
  });
}

/**
 * User interaction prompt dialog (for user input)
 */
export function promptDialog(msg, defaultValue = null) {
  return new Promise(function (resolve, reject) {
    let value = window.prompt(msg, defaultValue);

    return value ? resolve(value) : reject(null);
  });
}
