const { h } = require('preact');

function UserRequired() {
  return (
    <div>
      <p>You must have a user account to access this feature.</p>
      <ul className="table-view">
        <li className="table-view-cell">
          <a className="navigate-right" href="/register">
            Register New User
          </a>
        </li>
        <li className="table-view-cell">
          <a className="navigate-right" href="/login">
            User Login
          </a>
        </li>
      </ul>
    </div>
  );
}

export default UserRequired;
