import { array } from 'prop-types';
import { Component, h } from 'preact';
import store from '../../client/store';

class Alerts extends Component {
  static propTypes = {
    alerts: array,
  };

  renderAlert(message) {
    let isError = message.type === 'error';
    let messageClasses = `toast ${isError ? ' toast-error' : ''}`;

    return (
      <div className={messageClasses} key={'notification-id=' + message.id}>
        {message.message}
      </div>
    );
  }

  render() {
    let { alerts } = this.props;

    if (!alerts || alerts.length === 0) {
      return null;
    }

    return (
      <div className="toast-container">
        {alerts.map(this.renderAlert)}
      </div>
    );
  }
}

module.exports = store.subscribe(Alerts, {
  alerts: 'ui.alerts',
});
