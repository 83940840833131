const { Component, h } = require('preact');
const Async = require('./Async');
const MessagesList = require('./partials/MessagesList');
const messageActions = require('../client/actions/messages');
const store = require('../client/store');

class Messages extends Component {
  fetchAndRenderMessages() {
    return messageActions.remote.findByCurrentUser()
      .then(messages => {
        if (messages.length === 0) {
          return this.renderNoMessages();
        }

        return <MessagesList messages={messages} />;
      });
  }

  renderNoMessages() {
    return (
      <div className="content-padded">
        <p>You currently have no messages.</p>
        <a href="/search">Search for a Church to Follow</a>
      </div>
    );
  }

  render() {
    let { user } = this.props;

    return (
      <div className="card">
        {user ? <Async promise={this.fetchAndRenderMessages()} /> : this.renderNoMessages()}
      </div>
    );
  }
}

module.exports = store.subscribe(Messages, {
  user: 'user',
});
