
function diff (a1, a2) {
  return a2.filter(v => !a1.includes(v));
}

function flatten(arr) {
  return [].concat(...arr);
}

function unique(arr) {
  return Array.from(new Set(arr));
}

function uniqueById(array) {
  return uniqueByProperty(array, 'id');
}

function uniqueByProperty(array, propertyName) {
  return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i);
}

module.exports = {
  diff,
  flatten,
  unique,
  uniqueById,
};
