const { Component, h } = require('preact');
const PropTypes = require('prop-types');

class PromptDialog extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    if (typeof this.props.afterRender === 'function') {
      this.props.afterRender();
    }
  }

  componentDidUnmount() {
    this.handleClose();
  }

  propTypes = {
    children: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
  }

  handleClose = (e) => {
    e.preventDefault();

    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  };

  render() {
    const modalApi = {
      close: this.handleClose,
    };

    return (
      <div id="promptDialogModal" className={'modal' + (this.props.isOpen ? ' active' : '')}>
        <header className="bar bar-nav">
          <a className="icon icon-close pull-right" href="#" onClick={this.handleClose} />
          <h1 className="title">Modal</h1>
        </header>

        <div className="content">
          <p className="content-padded">
            {this.props.children[0](modalApi)}
          </p>
        </div>
      </div>
    );
  }
}

module.exports = PromptDialog;
