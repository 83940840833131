const Preact = require('preact');
const toystore = require('toystore');
const toystoreReact = require('toystore-react')(Preact);

const store = toystore.create({
  ui: {
    alerts: [],
    route: {
      path: '/',
      params: {},
    },
    tab: 'search',
  },
  user: null,
});

// Use partial application to add the 'subscribe' method from toystore-react, bound to this store
store.subscribe = (Component, mapping) => toystoreReact.subscribe(store, Component, mapping);

module.exports = store;
