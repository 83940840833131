const ApiForm = require('./ApiForm');
const httpActions = require('../client/actions/http');
const messageActions = require('../client/actions/messages');
const { Component, h } = require('preact');
const { error } = require('../client/actions/notifications');
const navigationActions = require('../client/actions/navigation');
const store = require('../client/store');
const UserRequired = require('./UserRequired');

class ChurchForm extends Component {
  onSubmit = (e, data) => {
    messageActions.remote.create(data)
      .then(msg => {
        navigationActions.navigateTo('/messages/' + msg.id);
      })
      .catch(error);
  };

  renderForm() {
    return (
      <ApiForm onSubmit={this.onSubmit} key="MessageForm">
        <h3>Message</h3>

        <p>This message will be sent to everyone subscribed to this church.</p>
        <textarea name="content" rows="4" required />

        <p>Message visibility</p>
        <div>
          <p><label><input type="radio" name="isPublic" value="1" checked /> Public - anyone on ChurchMint or website</label></p>
          <p><label><input type="radio" name="isPublic" value="0" /> Private - sent to subscribers only</label></p>
        </div>

        <input type="hidden" name="churchId" value={httpActions.getQueryStringParam('church')} />
        <button className="btn btn-positive btn-block">Send Message</button>
      </ApiForm>
    );
  }

  render() {
    let { user } = this.props;

    if (!user) {
      return <UserRequired />;
    }

    return (
      <div className="flex-container m2">
        <div className="flex-item">
          {this.renderForm()}
        </div>
      </div>
    );
  }
}

module.exports = store.subscribe(ChurchForm, {
  user: 'user',
});
