const actions = require('./actions');
const { h } = require('preact');
const nanohref = require('nanohref');
const router = require('./router');
const store = require('./store');
const parseUrl = require('url-lib/parseUrl');

// Route components
const ChurchForm = require('../components/ChurchForm');
const ChurchView = require('../components/ChurchView');
// const Homepage = require('../components/Homepage');
const Messages = require('../components/Messages');
const MessageForm = require('../components/MessageForm');
const Search = require('../components/Search');
const ServerError = require('../components/ui/ServerError');
const UserProfile = require('../components/UserProfile');
const UserLogin = require('../components/UserLogin');
const UserRegister = require('../components/UserRegister');

// Define app routes
router.get(['/', '/search'], () => h(Search));
router.get('/profile', () => h(UserProfile));
router.get('/login', () => h(UserLogin));
router.get('/logout', () => {
  actions.users.remote.logout();
  window.history.back();
});
router.get('/register', () => h(UserRegister));
router.get('/messages', () => h(Messages));
router.get('/messages/new', () => h(MessageForm));
router.get('/churches/new', () => h(ChurchForm));
router.get('/:shortcode', (params) => {
  return actions.churches.remote.findByShortcode(params.shortcode)
    .then((church) => h(ChurchView, { church }))
    .catch((error) => h(ServerError, { error }));
});

// Error 404 catch-all
router.get('*', () => {
  let error = new Error('File not found. Check the URL and try again.');

  return h(ServerError, { error });
});


/**
 * Normalize URL to trim start and end slashes off
 */
function normalizeUrl(url) {
  if (url.startsWith('/')) {
    url = url.substr(1);
  }

  if (url.endsWith('/')) {
    url = url.substring(0, url.length - 1);
  }

  return url;
}

function getContent() {
  let content;
  // let hashRoute = router.getHashbangValue();
  // let matched = router.match('/' + hashRoute);
  let pathRoute = parseUrl(window.location.href).pathname;
  let normalizedRoute = normalizeUrl(pathRoute);
  let matched = router.match('/' + normalizedRoute);

  if (matched !== false) {
    content = matched.callback(matched.params);
  } else {
    console.log('Route 404'); // eslint-disable-line no-console
  }

  if (!(content instanceof Promise)) {
    return Promise.resolve(content);
  }

  return content;
}

function onPathChange(newPath) {
  let normalizedRoute = normalizeUrl(newPath);

  // Set route to trigger update
  store.set('ui.route', {
    path: normalizedRoute || '/',
    params: {},
  });
}

// Setup nanohref (intercepts all local href clicks and runs this callback)
nanohref(function _handleHrefClick(loc) {
  history.pushState(null, document.title, loc.href);
  onPathChange(loc.pathname);
});

// When users click the browser 'Back' button
window.addEventListener('popstate', function _handleHistoyBack() {
  onPathChange(window.location.pathname);
});

module.exports = {
  getContent,
};
