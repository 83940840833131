export const firebase = require('firebase/app').default;
require('firebase/auth');
require('firebase/firestore');
require('firebase/messaging');

firebase.initializeApp({
  name: 'ChurchMint',
  apiKey: 'AIzaSyAYFutmpiCOwWbcZTX1K0yiLHAHuPk-8M4', // process.env.FIREBASE_API_KEY,
  authDomain: 'churchmint-production.firebaseapp.com',
  messagingSenderId: '220588028027', // process.env.FIREBASE_MESSAGING_SENDER_ID,
  projectId: 'churchmint-production',
});

// Mock messaging if not supported
const firebaseMessagingMock = {
  _isMock: true,
  isSupported: () => false,
  onMessage: () => null,
  onTokenRefresh: () => null,
  getToken: () => Promise.resolve(false),
  requestPermission: () => Promise.resolve(false),
  useServiceWorker: () => null,
};

// Expose Firebase services we are using
export const auth = firebase.auth();
export const db = firebase.firestore();
export const messaging = firebase.messaging && firebase.messaging.isSupported() ? firebase.messaging() : firebaseMessagingMock;
export const messagingSupported = firebase.messaging.isSupported;

// Config
auth.useDeviceLanguage();
auth.settings.appVerificationDisabledForTesting = process.env.NODE_ENV !== 'production';


/**
 * Query snapshot to array of results
 */
export function snapshotToArray(snapshot) {
  let results = [];

  if (snapshot.empty) {
    return results;
  }

  snapshot.forEach(doc => {
    let obj = Object.assign({ id: doc.id }, doc.data());

    results.push(obj);
  });

  return results;
}
