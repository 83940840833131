import { auth, db, snapshotToArray } from '../firebase';
const store = require('../store');
const { unique } = require('../../../functions/shared/arrays');
const { error } = require('./notifications');

/**
 * Remote/HTTP actions
 */
export const remote = {
  addPushTokenToCurrentUser(pushToken) {
    let userInfo = getCurrentUser();

    // User already has this token - skip adding it
    if (userInfo.pushTokens.includes(pushToken)) {
      return Promise.resolve(userInfo);
    }

    // Add push token to curent user account
    userInfo.pushTokens.push(pushToken);
    userInfo.pushTokens = unique(userInfo.pushTokens);

    return exports.remote.updateCurrentUser(userInfo);
  },

  loginWithPhoneNumber(tel, appVerifier) {
    let phoneNumber = formatUserPhoneNumber(tel); // Remove any non-digits

    return auth.signInWithPhoneNumber(phoneNumber, appVerifier);
  },

  logout() {
    return auth.signOut()
      .then(() => store.set('user', null));
  },

  updateUserProfile(userData) {
    const currentUser = getCurrentUser();

    if (!currentUser) {
      return;
    }

    if (userData.displayName) {
      currentUser.updateProfile({
        displayName: userData.displayName,
      });
    }

    if (userData.email) {
      currentUser.updateEmail(userData.email);
    }
  },

  /**
   * Does a user exist with the provided phoneNumber?
   *
   * @return {Promise<boolean>}
   */
  hasUserWithPhoneNumber(tel) {
    let phoneNumber = formatUserPhoneNumber(tel);

    return db.collection('users')
      .where('phoneNumber', '==', phoneNumber)
      .get()
      .then(snapshot => {
        let docs = snapshotToArray(snapshot);

        return docs.length > 0;
      });
  },

  /**
   * Subscribe current user to church
   */
  subscribeToChurch(church) {
    let user = getCurrentUser();

    if (!user) {
      handleNoUser();
      return Promise.resolve();
    }

    // Add church shortcode
    user.following.churchIds.push(church.shortcode);
    user.following.churchIds = unique(user.following.churchIds);

    updateLocalUser(user);

    return db.collection('users')
      .doc(user.uid)
      .set(user);
  },

  /**
   * Update user data in the 'users' collection
   */
  updateCurrentUser(userInfo) {
    let user = getCurrentUser();

    return db.collection('users')
      .doc(user.uid)
      .set(userInfo, { merge: true })
      .then(() => {
        updateLocalUser(userInfo);
        return userInfo;
      });
  },

  /**
   * Unsubscribe current user from church
   */
  unsubscribeFromChurch(church) {
    let user = getCurrentUser();

    if (!user) {
      handleNoUser();
      return Promise.resolve();
    }

    // Remove church shortcode
    let codeIndex = user.following.churchIds.findIndex(code => code === church.shortcode);

    if (codeIndex !== -1) {
      user.following.churchIds.splice(codeIndex, 1); // delete 1 item at found index
    }

    updateLocalUser(user);

    return db.collection('users')
      .doc(user.uid)
      .set(user);
  },
};

/**
 * Is the current user following the provided church?
 *
 * @return {Boolean}
 */
export function isFollowingChurch(church) {
  const user = getCurrentUser();

  return user ? user.following.churchIds.includes(church.shortcode) : false;
}

export function updateLocalUser(userInfo) {
  store.set('user', userInfo);
}

/**
 * Format user phone number to standard
 */
export function formatUserPhoneNumber(tel) {
  let phoneNumber = tel.replace(/[^\d+]/g, ''); // Remove any non-digits

  // Add leading '1' if not present already (for US/Canada numbers...)
  if (!phoneNumber.startsWith('+1')) {
    phoneNumber = '+1' + phoneNumber;
  }
  if (!phoneNumber.startsWith('+')) {
    phoneNumber = '+' + phoneNumber;
  }

  return phoneNumber;
}

/**
 * New/blank user object
 *
 * @return {object}
 */
export function getNewUserObject() {
  return {
    displayName: '',
    email: '',
    emailVerified: false,
    following: {
      churchIds: [],
      groupIds: [],
    },
    isAnonymous: true,
    phoneNumber: '',
    photoURL: '',
    pushTokens: [],
    uid: '',
  };
}

export function getCurrentUser(userObj = false) {
  let newUser = getNewUserObject();
  let user = userObj || store.get('user') || auth.currentUser;

  if (!user) {
    return false;
  }

  return Object.assign(newUser, {
    displayName: user.displayName,
    email: user.email,
    emailVerified: user.emailVerified,
    following: Object.assign(newUser.following, user.following),
    isAnonymous: user.isAnonymous || false,
    phoneNumber: user.phoneNumber,
    photoURL: user.photoURL,
    pushTokens: user.pushTokens || [],
    uid: user.uid,
  });
}

/**
 *
 */
export function handleNoUser() {
  error('You must be logged in to subscribe to churches.');
}
