const { Component, h } = require('preact');
const Async = require('./Async');
const Menu = require('./ui/Menu');
const churchActions = require('../client/actions/churches');
const messagesActions = require('../client/actions/messages');
const userActions = require('../client/actions/users');
const ChurchesList = require('./partials/ChurchesList');
const store = require('../client/store');

class UserProfile extends Component {
  fetchAndRenderChurches(user) {
    return churchActions.remote.findByUserFollowing(user)
      .then(churches => {
        if (churches.length === 0) {
          return this.renderNoChurches();
        }

        return <ChurchesList churches={churches} title="Churches You Subscribe To" />;
      });
  }

  renderNoChurches() {
    return (
      <div className="content-padded">
        <p>You are not currently following any churches</p>
        <a href="/search">Search for a Church to Follow</a>
      </div>
    );
  }

  renderUserNotLoggedIn() {
    return (
      <div className="flex-container m2">
        <div className="flex-item">
          <div>
            <p>You are not logged in.</p>
            <ul className="table-view">
              <li className="table-view-cell">
                <a className="navigate-right" href="/register">
                  Register New User
                </a>
              </li>
              <li className="table-view-cell">
                <a className="navigate-right" href="/login">
                  User Login
                </a>
              </li>
            </ul>
            <div className="content-padded">
              <h4>Why Create An Account?</h4>
              <p>An account can be helpful if you upgrade or replace your phone, or
              if you use multiple devices on a regular basis and want them to say in
              sync.</p>
              <p>You can still follow churches and receive messages without an
              account, but it is on a per-device, and per-app install basis, so if you
              lose your device, you will have to re-follow everything with your new
              device.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderUserProfile() {
    let { user } = this.props;

    // Menu options
    let options = [
      {
        title: 'Refresh Push Token',
        help: 'If you are not receiving push messages',
        onClick: () => messagesActions.remote.requestPermissionAndUpdateToken(),
      },
      {
        title: 'Logout',
        onClick: () => userActions.remote.logout(),
      },
    ];

    return (
      <div>
        <div className="profile-view">
          <ul className="table-view">
            <li className="table-view-cell">
              <Menu title="User Actions" options={options} />
              <h1 className="heading">{user.displayName || user.phoneNumber}</h1>
              <p>{user.email}</p>
            </li>
          </ul>
        </div>
        <Async promise={this.fetchAndRenderChurches(user)} />
      </div>
    );
  }

  render() {
    let { user } = this.props;

    return (
      user ? this.renderUserProfile() : this.renderUserNotLoggedIn()
    );
  }
}

module.exports = store.subscribe(UserProfile, {
  user: 'user',
});
