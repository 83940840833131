const { h, Component } = require('preact');

class Toggle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: props.isActive || false,
    };
  }

  onClick = (e) => {
    let newState = !this.state.isActive;
    this.setState({ isActive: newState });

    if (this.props.onClick) {
      this.props.onClick(e, newState);
    }
  };

  render() {
    return (
      <div className={'toggle' + (this.state.isActive ? ' active' : '')} onClick={this.onClick}>
        <div className="toggle-handle" />
      </div>
    );
  }
}

module.exports = Toggle;
