const { Component, h } = require('preact');
const Async = require('./Async');
const Menu = require('./ui/Menu');
const Toggle = require('./ui/Toggle');
const MessagesList = require('./partials/MessagesList');
const messageActions = require('../client/actions/messages');
const usersActions = require('../client/actions/users');

class ChurchView extends Component {
  fetchAndRenderMessages() {
    let { church } = this.props;

    return messageActions.remote.findByChurchShortcode(church.shortcode)
      .then(messages => <MessagesList messages={messages} />);
  }

  onSubscribeToggle = (e, isActive) => {
    let { church } = this.props;

    if (isActive) {
      usersActions.remote.subscribeToChurch(church);
    } else {
      usersActions.remote.unsubscribeFromChurch(church);
    }
  };

  render() {
    let { church } = this.props;

    // Menu options
    let options = [
      {
        title: 'New Message',
        href: '/messages/new/?church=' + church.id,
      },
    ];

    return (
      <div className="profile-view">
        <ul className="table-view">
          <li className="table-view-cell">
            <Menu title="Church Actions" options={options} />
            <h1 className="heading">{church.name}</h1>
          </li>
          <li className="table-view-cell">
            Subscribe To Messages From This Church
            <Toggle onClick={this.onSubscribeToggle} isActive={usersActions.isFollowingChurch(church)} />
          </li>
        </ul>

        <div className="card">
          {church ? <Async promise={this.fetchAndRenderMessages()} /> : null }
        </div>
      </div>
    );
  }
}

module.exports = ChurchView;
