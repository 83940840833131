const { h } = require('preact');

function ServerError(props) {
  return (
    <div className="card">
      <div className="content-padded">
        Error: {props.error.message}
      </div>
    </div>
  );
}

module.exports = ServerError;
