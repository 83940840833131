// Actions
import * as churches from './actions/churches';
import * as messages from './actions/messages';
import * as users from './actions/users';

// Exports
module.exports = {
  churches,
  messages,
  users,
};
