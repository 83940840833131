const ApiForm = require('./ApiForm');
const churchActions = require('../client/actions/churches');
const { Component, h } = require('preact');
const { error } = require('../client/actions/notifications');
const navigationActions = require('../client/actions/navigation');
const store = require('../client/store');
const UserRequired = require('./UserRequired');

class ChurchForm extends Component {
  onSubmit = (e, data) => {
    churchActions.remote.create(data)
      .then(church => {
        navigationActions.navigateTo('/' + church.shortcode);
      })
      .catch((err) => {
        console.error(err);
        error(err.message || 'No church exists with the provided code');
      });
  };

  renderForm() {
    return (
      <ApiForm onSubmit={this.onSubmit} key="ChurchForm">
        <h3>Church Info</h3>
        <p>Church Name</p>
        <input type="text" name="name" placeholder="First Baptist Church City" required />
        <p>Location (City/State)</p>
        <input type="text" name="location" placeholder="City, ST" required />

        <hr />

        <h3>Shortcode</h3>
        <p>Shortcode (4+ characters)</p>
        <input className="text-uppercase" type="text" name="shortcode" min="4" placeholder="FBCCITY" required />
        <p>This is how people will find your church, so it should be short and memorable.</p>
        <button className="btn btn-positive btn-block">Create Church</button>
      </ApiForm>
    );
  }

  render() {
    let { user } = this.props;

    if (!user) {
      return <UserRequired />;
    }

    return (
      <div className="flex-container m2">
        <div className="flex-item">
          {this.renderForm()}
        </div>
      </div>
    );
  }
}

module.exports = store.subscribe(ChurchForm, {
  user: 'user',
});
